/* global tableau */
import React, { useEffect, useState, useContext } from 'react';
import { DropdownSelect } from '@tableau/tableau-ui';
import styled from 'styled-components';
import IRR from './IRR';
import KPIContext from '../context/KPI/kpiContext';

const KPI = () => {
  const kpiContext = useContext(KPIContext);
  const { worksheet, kpi, setWorksheet, setKPI } = kpiContext;
  const [worksheets, setWorksheets] = useState([]);

  useEffect(() => {
    tableau.extensions.initializeAsync({ configure }).then(function () {
      const dashboardContent = tableau.extensions.dashboardContent;
      const worksheets = dashboardContent.dashboard.worksheets;
      setWorksheets(worksheets);
    });
  }, []);

  const configure = () => {};

  const KPIs = [
    { name: 'Annuity', component: <IRR /> },
    { name: 'Annual Percentage Yield (APY)', component: <IRR /> },
    { name: 'Bond Yield', component: <IRR /> },
    { name: 'Compound Interest', component: <IRR /> },
    { name: 'Internal Rate of Return (IRR)', component: <IRR /> },
    { name: 'Net Worth', component: <IRR /> },
    { name: 'Net Present Value (NPV)', component: <IRR /> },
    { name: 'Present Value', component: <IRR /> },
    { name: 'Return on Investment (ROI)', component: <IRR /> },
    { name: 'Savings', component: <IRR /> },
    { name: '...', component: <IRR /> },
    // { name: "s", component: <IRR /> },
  ];

  const kpiCondition = !(kpi === '' || kpi === 'Select KPI');
  const worksheetCondition = !(worksheet === '' || worksheet === 'Select Worksheet');

  return (
    <Container>
      <DropContainer>
        <DropdownSelect
          label='Select Worksheet'
          kind='outline'
          onChange={(e) => setWorksheet(e.target.value)}
        >
          <option> Select Worksheet </option>
          {worksheets &&
            worksheets.map((wrksht) => {
              if (worksheet === wrksht.name) {
                return (
                  <option selected key={wrksht.name}>
                    {wrksht.name}
                  </option>
                );
              } else {
                return <option key={wrksht.name}> {wrksht.name} </option>;
              }
            })}
        </DropdownSelect>
      </DropContainer>

      <DropContainer>
        <DropdownSelect label='Select KPI' kind='outline' onChange={(e) => setKPI(e.target.value)}>
          <option> Select KPI </option>;
          {KPIs.map(({ name }) => {
            if (kpi === name) {
              return (
                <option selected key={name}>
                  {name}
                </option>
              );
            } else {
              return <option key={name}> {name} </option>;
            }
          })}
        </DropdownSelect>
      </DropContainer>

      {worksheetCondition && kpiCondition ? <IRR key={worksheet} /> : ''}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 10px 20px 0 10px; */
`;

const DropContainer = styled.div`
  width: 40%;
  margin: 10px 0;
`;

export default KPI;
