import React, { useReducer } from "react";
import KPIContext from "./kpiContext";
import KPIReducer from "./kpiReducer";
import {
  SET_CONGFIGURE,
  SET_WORKSHEET,
  SET_KPI,
  SET_YEAR,
  SET_VALUE,
} from "../types";

const KPIState = (props) => {
  const initialState = {
    worksheet: "",
    kpi: "",
    irr: {
      year: "",
      value: "",
    },
  };

  const [state, dispatch] = useReducer(KPIReducer, initialState);

  const setWorksheet = (worksheet) => {
    dispatch({ type: SET_WORKSHEET, payload: worksheet });
  };

  const setKPI = (kpi) => {
    dispatch({ type: SET_KPI, payload: kpi });
  };

  const setYear = (year) => {
    dispatch({ type: SET_YEAR, payload: year });
  };

  const setValue = (value) => {
    dispatch({ type: SET_VALUE, payload: value });
  };

  const loadKPI = (obj) => {
    dispatch({ type: SET_CONGFIGURE, payload: obj });
  };

  return (
    <KPIContext.Provider
      value={{
        worksheet: state.worksheet,
        kpi: state.kpi,
        irr: state.irr,
        stateValue: {
          worksheet: state.worksheet,
          kpi: state.kpi,
          irr: state.irr,
        },
        setWorksheet,
        setKPI,
        setYear,
        setValue,
        loadKPI,
      }}
    >
      {" "}
      {props.children}
    </KPIContext.Provider>
  );
};

export default KPIState;
