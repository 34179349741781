/* eslint-disable react-hooks/exhaustive-deps */
/* global tableau */
import React, { useEffect, useState, useContext } from "react";
import { DropdownSelect } from "@tableau/tableau-ui";
import styled from "styled-components";
import KPIContext from "../context/KPI/kpiContext";

const IRR = () => {
  const kpiContent = useContext(KPIContext);
  const { worksheet, irr, setYear, setValue } = kpiContent;
  const { year, value } = irr;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    tableau.extensions.initializeAsync({ configure }).then(function () {
      const dashboardContent = tableau.extensions.dashboardContent;
      const worksheets = dashboardContent.dashboard.worksheets;
      const worksheetO = worksheets.filter(
        (wrksht) => wrksht.name === worksheet
      )[0];
      async function fetchColumns() {
        const dataTable = await worksheetO.getSummaryDataAsync();
        const columns = dataTable.columns;
        setColumns(columns);
      }
      fetchColumns();
    });
  }, []);

  const configure = () => {};

  return (
    <Container>
      <DropContainer>
        <DropdownSelect
          label="Select Year Column"
          kind="outline"
          onChange={(e) => setYear(e.target.value)}
        >
          <option> Select Year Column </option>;
          {columns.map(({ fieldName, fieldId }) => {
            if (year === fieldName) {
              return (
                <option selected key={fieldId}>
                  {fieldName}{" "}
                </option>
              );
            } else {
              return <option key={fieldId}> {fieldName} </option>;
            }
          })}
        </DropdownSelect>
      </DropContainer>

      <DropContainer>
        <DropdownSelect
          label="Select Value Column"
          kind="outline"
          onChange={(e) => setValue(e.target.value)}
        >
          <option> Select Value Column </option>;
          {columns.map(({ fieldName, fieldId }) => {
            if (value === fieldName) {
              return (
                <option selected key={fieldId}>
                  {fieldName}{" "}
                </option>
              );
            } else {
              return <option key={fieldId}> {fieldName} </option>;
            }
          })}
        </DropdownSelect>
      </DropContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropContainer = styled.div`
  width: 40%;
  margin: 10px 0;
`;

export default IRR;
