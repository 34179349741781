/* global tableau */
import React, { useEffect, useState, useContext } from 'react';
import { popUpURL } from './Constants';
import { IRR } from './Functions';
import FormatContext from './context/Formatting/formatContext';
import KPIContext from './context/KPI/kpiContext';
import Render from './components/Render';

const Extension = () => {
  const formatContext = useContext(FormatContext);
  const {
    kpiFormat,
    prefix,
    suffix,
    prefixCheck,
    suffixCheck,
    loadKPIFormat,
    loadPrefix,
    loadSuffix,
    loadCheck,
  } = formatContext;
  const { decimals } = kpiFormat;

  const kpiContext = useContext(KPIContext);
  const { loadKPI, worksheet, irr } = kpiContext;
  const { year, value } = irr;

  const [irrValue, setIrrValue] = useState('');

  useEffect(() => {
    tableau.extensions.initializeAsync({ configure }).then(function () {
      loadSettings();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (worksheet !== '') {
      calculateIRR();
      const dashboardContent = tableau.extensions.dashboardContent;
      const worksheets = dashboardContent.dashboard.worksheets;
      const wrkshtObj = worksheets.find((sheet) => sheet.name === worksheet);
      console.log('wrkshtObj');
      console.log(wrkshtObj);
      wrkshtObj.addEventListener(tableau.TableauEventType.FilterChanged, filterChangedHandler);
      wrkshtObj.addEventListener(
        tableau.TableauEventType.MarkSelectionChanged,
        filterChangedHandler
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksheet]);

  const filterChangedHandler = (something) => {
    console.log(something);
    calculateIRR();
  };

  const configure = async () => {
    console.log('configure', popUpURL + '/configure');
    const res = await tableau.extensions.ui.displayDialogAsync(popUpURL + '#configure', null, {
      height: 500,
      width: 800,
    });
    if (res) {
      loadSettings();
    }
  };

  const calculateIRR = async () => {
    const worksheets = tableau.extensions.dashboardContent.dashboard.worksheets;
    const currentWorksheet = worksheets.filter((wks) => wks.name === worksheet)[0];
    const dataTable = await currentWorksheet.getSummaryDataAsync();
    const columns = dataTable.columns;
    const yearIndex = columns.filter((column) => column.fieldName === year)[0].index;
    const valueIndex = columns.filter((column) => column.fieldName === value)[0].index;
    const valueArr = [];
    const dict = [];

    for (var j = 0; j < dataTable.totalRowCount; j++) {
      dict.push({
        year: dataTable.data[j][yearIndex].value,
        value: dataTable.data[j][valueIndex].value,
      });
    }
    dict.sort((a, b) => (a.year > b.year ? 1 : b.year > a.year ? -1 : 0));

    for (var i = 0; i < dataTable.totalRowCount; i++) {
      valueArr[i] = dict[i].value;
    }
    setIrrValue(IRR(valueArr));
  };

  const loadSettings = () => {
    console.log('loading Settings');
    const kpiJson = tableau.extensions.settings.get('kpi');
    const formatJson = tableau.extensions.settings.get('format');
    const prefixJson = tableau.extensions.settings.get('prefix');
    const suffixJson = tableau.extensions.settings.get('suffix');
    const prefixCheckJson = tableau.extensions.settings.get('prefixCheck');
    const suffixCheckJson = tableau.extensions.settings.get('suffixCheck');
    if (prefixCheckJson !== undefined) loadCheck('prefix', JSON.parse(prefixCheckJson).prefixCheck);
    if (suffixCheckJson !== undefined) loadCheck('suffix', JSON.parse(suffixCheckJson).suffixCheck);
    if (kpiJson !== undefined) loadKPI(JSON.parse(kpiJson));
    if (formatJson !== undefined) loadKPIFormat(JSON.parse(formatJson));
    if (prefixJson !== undefined) loadPrefix(JSON.parse(prefixJson));
    else console.log('No Prefix');
    if (suffixJson !== undefined) loadSuffix(JSON.parse(suffixJson));
    else console.log('No Suffx');
  };

  return <Render irrValue={irrValue} />;
};

export default Extension;
