import React, { useContext } from 'react';
import styled from 'styled-components';
import FormatContext from '../context/Formatting/formatContext';

const Render = ({ irrValue }) => {
  const formatContext = useContext(FormatContext);
  const { kpiFormat, prefix, suffix, prefixCheck, suffixCheck } = formatContext;
  const { decimals } = kpiFormat;

  return (
    <Wrapper>
      {prefixCheck && (
        <Prefix
          fontColor={prefix.fontColor}
          fontSize={prefix.fontSize}
          fontFamily={prefix.fontFamily}
          bgColor={prefix.bgColor}
          textAlign={prefix.textAlign}
          marginTop={prefix.margin.top}
          marginRight={prefix.margin.right}
          marginBottom={prefix.margin.bottom}
          marginLeft={prefix.margin.left}
          paddingTop={prefix.padding.top}
          paddingRight={prefix.padding.right}
          paddingBottom={prefix.padding.bottom}
          paddingLeft={prefix.padding.left}
        >
          {prefix.input}
        </Prefix>
      )}
      <KPI
        fontColor={kpiFormat.fontColor}
        fontSize={kpiFormat.fontSize}
        decimals
        fontFamily={kpiFormat.fontFamily}
        bgColor={kpiFormat.bgColor}
        textAlign={kpiFormat.textAlign}
        marginTop={kpiFormat.margin.top}
        marginRight={kpiFormat.margin.right}
        marginBottom={kpiFormat.margin.bottom}
        marginLeft={kpiFormat.margin.left}
        paddingTop={kpiFormat.padding.top}
        paddingRight={kpiFormat.padding.right}
        paddingBottom={kpiFormat.padding.bottom}
        paddingLeft={kpiFormat.padding.left}
      >
        {isNaN(irrValue)
          ? 'Not calculable'
          : irrValue === ''
          ? 'Configure to get started'
          : `${(irrValue * 100).toFixed(decimals)}%`}
      </KPI>
      {suffixCheck && (
        <Suffix
          fontColor={suffix.fontColor}
          fontSize={suffix.fontSize}
          fontFamily={suffix.fontFamily}
          bgColor={suffix.bgColor}
          textAlign={suffix.textAlign}
          marginTop={suffix.margin.top}
          marginRight={suffix.margin.right}
          marginBottom={suffix.margin.bottom}
          marginLeft={suffix.margin.left}
          paddingTop={suffix.padding.top}
          paddingRight={suffix.padding.right}
          paddingBottom={suffix.padding.bottom}
          paddingLeft={suffix.padding.left}
        >
          {suffix.input}
        </Suffix>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const KPI = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ fontColor }) => fontColor};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-family: ${({ fontFamily }) => fontFamily};
  display: flex;
  justify-content: ${({ textAlign }) => textAlign};
  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px `};

  padding: ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
    `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px `};
`;

const Prefix = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ fontColor }) => fontColor};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-family: ${({ fontFamily }) => fontFamily};
  display: flex;
  justify-content: ${({ textAlign }) => textAlign};
  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px `};
  padding: ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
    `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px `};
`;

const Suffix = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ fontColor }) => fontColor};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-family: ${({ fontFamily }) => fontFamily};
  display: flex;
  justify-content: ${({ textAlign }) => textAlign};
  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px `};
  padding: ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
    `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px `};
`;

export default Render;
