import React, { useReducer } from 'react';
import FormatContext from './formatContext';
import FormatReducer from './formatReducer';
import {
  SET_BGCOLOR,
  SET_FONTCOLOR,
  SET_INPUT,
  SET_FONTFAMILY,
  SET_FONTSIZE,
  SET_TEXTALIGN,
  SET_CHECK,
  SET_DECIMAL,
  SET_PADDING,
  SET_MARGIN,
  LOAD_KPI,
  LOAD_PREFIX,
  LOAD_SUFFIX,
  LOAD_CHECK,
} from '../types';

const FormatState = (props) => {
  const initialState = {
    prefixCheck: true,
    suffixCheck: false,
    kpiFormat: {
      bgColor: '#ffffff',
      fontColor: '#000000',
      fontSize: 25,
      fontFamily: 'Arial',
      textAlign: 'center',
      decimals: '1',
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      padding: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
    },
    prefix: {
      input: 'IRR',
      bgColor: '#ffffff',
      fontColor: '#888888',
      fontSize: 14,
      fontFamily: 'Arial',
      textAlign: 'center',
      margin: {
        top: '0',
        right: '0',
        bottom: '10',
        left: '0',
      },
      padding: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
    },
    suffix: {
      input: '',
      bgColor: '#ffffff',
      fontColor: '#000000',
      fontSize: 12,
      fontFamily: 'Arial',
      textAlign: 'center',
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      padding: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
    },
  };

  const [state, dispatch] = useReducer(FormatReducer, initialState);

  const setMargin = (type, place, value) => {
    console.log(type, place, value);
    dispatch({ type: SET_MARGIN, payload: { type, place, value } });
  };

  const setPadding = (type, place, value) => {
    dispatch({ type: SET_PADDING, payload: { type, place, value } });
  };

  const setBgColor = (type, value) => {
    dispatch({ type: SET_BGCOLOR, payload: { type, value } });
  };

  const setCheck = (type, value) => {
    console.log(type, value);
    dispatch({ type: SET_CHECK, payload: { type, value } });
  };

  const setInput = (type, value) => {
    console.log(type, value);
    dispatch({ type: SET_INPUT, payload: { type, value } });
  };

  const setFontColor = (type, value) => {
    dispatch({ type: SET_FONTCOLOR, payload: { type, value } });
  };

  const setFontSize = (type, value) => {
    console.log(type, value);
    dispatch({ type: SET_FONTSIZE, payload: { type, value } });
  };

  const setFontFamily = (type, value) => {
    dispatch({ type: SET_FONTFAMILY, payload: { type, value } });
  };

  const setTextAlign = (type, value) => {
    dispatch({ type: SET_TEXTALIGN, payload: { type, value } });
  };

  const setDecimals = (value) => {
    console.log('decimal: ' + value);
    dispatch({ type: SET_DECIMAL, payload: value });
  };

  const loadKPIFormat = (value) => {
    dispatch({ type: LOAD_KPI, payload: value });
  };

  const loadPrefix = (value) => {
    console.log(value);
    dispatch({ type: LOAD_PREFIX, payload: value });
  };

  const loadSuffix = (value) => {
    dispatch({ type: LOAD_SUFFIX, payload: value });
  };

  const loadCheck = (type, value) => {
    dispatch({ type: LOAD_CHECK, payload: { type, value } });
  };

  return (
    <FormatContext.Provider
      value={{
        kpiFormat: state.kpiFormat,
        prefix: state.prefix,
        suffix: state.suffix,
        prefixCheck: state.prefixCheck,
        suffixCheck: state.suffixCheck,
        setBgColor,
        setFontColor,
        setFontSize,
        setDecimals,
        setTextAlign,
        setFontFamily,
        setCheck,
        setInput,
        setMargin,
        setPadding,
        loadKPIFormat,
        loadPrefix,
        loadSuffix,
        loadCheck,
      }}
    >
      {props.children}
    </FormatContext.Provider>
  );
};

export default FormatState;
