import React, { useState } from "react";
import { ChromePicker, GithubPicker } from "react-color";
import { Button } from "@tableau/tableau-ui";
import invertColor from "invert-color";
// import Colors from "../../utils/colors";
// import ConfigField from "../components/ConfigField";
// import { parseColor } from "../../utils/graph";

export default function ColorPicker({
  color,
  changeColor,
  defaultColors,
  disableTransparent,
}) {
  color = color === "transparent" && disableTransparent ? "#ffffff" : color;

  const [show, toggleShow] = useState(false);

  const [current, setCurrent] = useState(color);

  function handleChoosenColor() {
    changeColor(current.hex);

    toggleShow(false);
  }

  function exampleColor(color) {
    if (!color) {
      return { color: "black" };
    }

    if (color.substr(0, 1) === "#") {
    //   color = Colors.hexToRGBA(color, 0.8);
    }

    if (color === "transparent") {
      return {
        backgroundColor: disableTransparent ? "white" : color,

        border: "none",

        boxShadow:
          "0 2px 10px -4px inset rgba(0,0,0,.4), 0 0 0 1px rgba(0,0,0,.2)",
      };
    }

    const parseAndInvert = invertColor(color);

    return {
      backgroundColor: color,

      color: parseAndInvert.invertedBW,

      border: "1px solid " + color,

      boxShadow: "0 2px 10px -4px " + color + ", 0 0 0 1px rgba(0,0,0,.2)",
    };
  }

  return (
    <>
      {show && (
        <div className="ColorPicker--colors">
          <ChromePicker
            width="100%"
            disableAlpha={true}
            color={current}
            onChange={(color) => setCurrent(color)}
          />

          {(defaultColors === undefined || defaultColors === true) && (
            <GithubPicker
              width="100%"
              onChange={(color) => setCurrent(color)}
              triangle="hide"
            //   colors={Colors.colors}
            />
          )}

          <div className="ColorPicker--actions">
            <Button kind="primary" onClick={handleChoosenColor}>
              Choose color
            </Button>

            <Button kind="outline" onClick={() => toggleShow(false)}>
              Cancel
            </Button>
          </div>
        </div>
      )}

      {!show && (
          <div className="ColorPicker--example">
          <button
            className="ColorPicker--btn"
            onClick={() => toggleShow(!show)}
            style={exampleColor(color)}
          >
            <div className="ColorPicker--current">{color}</div>
          </button>
        </div>
      )}
    </>
  );
}
