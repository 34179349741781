import React from 'react';
// import RawChangelog from '../../CHANGELOG.md';
import './about.css';

const About = () => {
  // const { version, version_release } = { version: '1.0', version_release: '12-03-2020' };
  const { version, version_release } = require('../../package.json');

  return (
    <div style={{ height: '200px' }}>
      <h2>About</h2>

      <p>
        Version: <span class='text-muted'>{version}</span>
        <br />
        Release date: <span class='text-muted'>{version_release}</span>
        <br />
        Support:{' '}
        <a href='mailto:support@appsfortableau.com' target='_blank' rel='noopener noreferrer'>
          support@appsfortableau.com
        </a>
        <br />
        Website:{' '}
        <a href='https://appsfortableau.infotopics.com' target='_blank' rel='noopener noreferrer'>
          https://appsfortableau.infotopics.com
        </a>
      </p>

      <div class='appsfortableau__logo'>
        <a href='https://appsfortableau.infotopics.com' target='_blank' rel='noopener noreferrer'>
          <img src='./logo_appsfortableau.svg' alt='Infotopics | Apps for Tableau' />
        </a>
      </div>

      {/* <div className='appsfortableau' style={{ marginTop: 25 }}>
        <div
          className='appsfortableau__changelog'
          dangerouslySetInnerHTML={{ __html: RawChangelog }}
        ></div>
      </div> */}
    </div>
  );
};

export default About;
