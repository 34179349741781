/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import {
  SET_BGCOLOR,
  SET_FONTCOLOR,
  SET_FONTSIZE,
  SET_TEXTALIGN,
  SET_DECIMAL,
  SET_FONTFAMILY,
  SET_CHECK,
  SET_INPUT,
  SET_PADDING,
  SET_MARGIN,
  KPI,
  PREFIX,
  SUFFIX,
  LOAD_KPI,
  LOAD_PREFIX,
  LOAD_SUFFIX,
  LOAD_CHECK,
} from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case SET_CHECK:
      switch (action.payload.type) {
        case PREFIX:
          return {
            ...state,
            prefixCheck: action.payload.value,
          };
        case SUFFIX:
          return {
            ...state,
            suffixCheck: action.payload.value,
          };
        default:
          return state;
      }
    case LOAD_CHECK:
      switch (action.payload.type) {
        case PREFIX:
          return {
            ...state,
            prefixCheck: action.payload.value,
          };
        case SUFFIX:
          return {
            ...state,
            suffixCheck: action.payload.value,
          };
        default:
          return state;
      }
    case SET_INPUT:
      switch (action.payload.type) {
        case PREFIX:
          return {
            ...state,
            prefix: {
              ...state.prefix,
              input: action.payload.value,
            },
          };
        case SUFFIX:
          return {
            ...state,
            suffix: {
              ...state.prefix,
              input: action.payload.value,
            },
          };
        default:
          return state;
      }
    case SET_BGCOLOR:
      switch (action.payload.type) {
        case KPI:
          return {
            ...state,
            kpiFormat: {
              ...state.kpiFormat,
              bgColor: action.payload.value,
            },
          };
        case PREFIX:
          return {
            ...state,
            prefix: {
              ...state.prefix,
              bgColor: action.payload.value,
            },
          };
        case SUFFIX:
          return {
            ...state,
            suffix: {
              ...state.suffix,
              bgColor: action.payload.value,
            },
          };
        default:
          return state;
      }
    case SET_FONTCOLOR:
      switch (action.payload.type) {
        case KPI:
          return {
            ...state,
            kpiFormat: {
              ...state.kpiFormat,
              fontColor: action.payload.value,
            },
          };
        case PREFIX:
          return {
            ...state,
            prefix: {
              ...state.prefix,
              fontColor: action.payload.value,
            },
          };
        case SUFFIX:
          return {
            ...state,
            suffix: {
              ...state.suffix,
              fontColor: action.payload.value,
            },
          };
        default:
          return state;
      }

    case SET_FONTSIZE:
      switch (action.payload.type) {
        case KPI:
          return {
            ...state,
            kpiFormat: {
              ...state.kpiFormat,
              fontSize: action.payload.value,
            },
          };
        case PREFIX:
          return {
            ...state,
            prefix: {
              ...state.prefix,
              fontSize: action.payload.value,
            },
          };
        case SUFFIX:
          return {
            ...state,
            suffix: {
              ...state.suffix,
              fontSize: action.payload.value,
            },
          };
        default:
          return state;
      }
    case SET_FONTFAMILY:
      switch (action.payload.type) {
        case KPI:
          return {
            ...state,
            kpiFormat: {
              ...state.kpiFormat,
              fontFamily: action.payload.value,
            },
          };
        case PREFIX:
          return {
            ...state,
            prefix: {
              ...state.prefix,
              fontFamily: action.payload.value,
            },
          };
        case SUFFIX:
          return {
            ...state,
            suffix: {
              ...state.suffix,
              fontFamily: action.payload.value,
            },
          };
        default:
          return state;
      }
    case SET_TEXTALIGN:
      switch (action.payload.type) {
        case KPI:
          return {
            ...state,
            kpiFormat: {
              ...state.kpiFormat,
              textAlign: action.payload.value,
            },
          };
        case PREFIX:
          return {
            ...state,
            prefix: {
              ...state.prefix,
              textAlign: action.payload.value,
            },
          };
        case SUFFIX:
          return {
            ...state,
            suffix: {
              ...state.suffix,
              textAlign: action.payload.value,
            },
          };
        default:
          return state;
      }
    case SET_DECIMAL:
      return {
        ...state,
        kpiFormat: {
          ...state.kpiFormat,
          decimals: action.payload,
        },
      };

    case SET_MARGIN:
      switch (action.payload.place) {
        case "top":
          switch (action.payload.type) {
            case KPI:
              return {
                ...state,
                kpiFormat: {
                  ...state.kpiFormat,
                  margin: {
                    ...state.kpiFormat.margin,
                    top: action.payload.value,
                  },
                },
              };
            case PREFIX:
              return {
                ...state,
                prefix: {
                  ...state.prefix,
                  margin: {
                    ...state.prefix.margin,
                    top: action.payload.value,
                  },
                },
              };
            case SUFFIX:
              return {
                ...state,
                suffix: {
                  ...state.suffix,
                  margin: {
                    ...state.suffix.margin,
                    top: action.payload.value,
                  },
                },
              };
          }
        case "right":
          switch (action.payload.type) {
            case KPI:
              return {
                ...state,
                kpiFormat: {
                  ...state.kpiFormat,
                  margin: {
                    ...state.kpiFormat.margin,
                    right: action.payload.value,
                  },
                },
              };
            case PREFIX:
              return {
                ...state,
                prefix: {
                  ...state.prefix,
                  margin: {
                    ...state.prefix.margin,
                    right: action.payload.value,
                  },
                },
              };
            case SUFFIX:
              return {
                ...state,
                suffix: {
                  ...state.suffix,
                  margin: {
                    ...state.suffix.margin,
                    right: action.payload.value,
                  },
                },
              };
          }
        case "bottom":
          switch (action.payload.type) {
            case KPI:
              return {
                ...state,
                kpiFormat: {
                  ...state.kpiFormat,
                  margin: {
                    ...state.kpiFormat.margin,
                    bottom: action.payload.value,
                  },
                },
              };
            case PREFIX:
              return {
                ...state,
                prefix: {
                  ...state.prefix,
                  margin: {
                    ...state.prefix.margin,
                    bottom: action.payload.value,
                  },
                },
              };
            case SUFFIX:
              return {
                ...state,
                suffix: {
                  ...state.suffix,
                  margin: {
                    ...state.suffix.margin,
                    bottom: action.payload.value,
                  },
                },
              };
          }
        case "left":
          switch (action.payload.type) {
            case KPI:
              return {
                ...state,
                kpiFormat: {
                  ...state.kpiFormat,
                  margin: {
                    ...state.kpiFormat.margin,
                    left: action.payload.value,
                  },
                },
              };
            case PREFIX:
              return {
                ...state,
                prefix: {
                  ...state.prefix,
                  margin: {
                    ...state.prefix.margin,
                    left: action.payload.value,
                  },
                },
              };
            case SUFFIX:
              return {
                ...state,
                suffix: {
                  ...state.suffix,
                  margin: {
                    ...state.suffix.margin,
                    left: action.payload.value,
                  },
                },
              };
          }
      }

      case SET_PADDING:
        switch (action.payload.place) {
          case "top":
            switch (action.payload.type) {
              case KPI:
                return {
                  ...state,
                  kpiFormat: {
                    ...state.kpiFormat,
                    padding: {
                      ...state.kpiFormat.padding,
                      top: action.payload.value,
                    },
                  },
                };
              case PREFIX:
                return {
                  ...state,
                  prefix: {
                    ...state.prefix,
                    padding: {
                      ...state.prefix.padding,
                      top: action.payload.value,
                    },
                  },
                };
              case SUFFIX:
                return {
                  ...state,
                  suffix: {
                    ...state.suffix,
                    padding: {
                      ...state.suffix.padding,
                      top: action.payload.value,
                    },
                  },
                };
            }
          case "right":
            switch (action.payload.type) {
              case KPI:
                return {
                  ...state,
                  kpiFormat: {
                    ...state.kpiFormat,
                    padding: {
                      ...state.kpiFormat.padding,
                      right: action.payload.value,
                    },
                  },
                };
              case PREFIX:
                return {
                  ...state,
                  prefix: {
                    ...state.prefix,
                    padding: {
                      ...state.prefix.padding,
                      right: action.payload.value,
                    },
                  },
                };
              case SUFFIX:
                return {
                  ...state,
                  suffix: {
                    ...state.suffix,
                    padding: {
                      ...state.suffix.padding,
                      right: action.payload.value,
                    },
                  },
                };
            }
          case "bottom":
            switch (action.payload.type) {
              case KPI:
                return {
                  ...state,
                  kpiFormat: {
                    ...state.kpiFormat,
                    padding: {
                      ...state.kpiFormat.padding,
                      bottom: action.payload.value,
                    },
                  },
                };
              case PREFIX:
                return {
                  ...state,
                  prefix: {
                    ...state.prefix,
                    padding: {
                      ...state.prefix.padding,
                      bottom: action.payload.value,
                    },
                  },
                };
              case SUFFIX:
                return {
                  ...state,
                  suffix: {
                    ...state.suffix,
                    padding: {
                      ...state.suffix.padding,
                      bottom: action.payload.value,
                    },
                  },
                };
            }
          case "left":
            switch (action.payload.type) {
              case KPI:
                return {
                  ...state,
                  kpiFormat: {
                    ...state.kpiFormat,
                    padding: {
                      ...state.kpiFormat.padding,
                      left: action.payload.value,
                    },
                  },
                };
              case PREFIX:
                return {
                  ...state,
                  prefix: {
                    ...state.prefix,
                    padding: {
                      ...state.prefix.padding,
                      left: action.payload.value,
                    },
                  },
                };
              case SUFFIX:
                return {
                  ...state,
                  suffix: {
                    ...state.suffix,
                    padding: {
                      ...state.suffix.padding,
                      left: action.payload.value,
                    },
                  },
                };
            }
        }

      
    case LOAD_KPI:
      return {
        ...state,
        kpiFormat: {
          ...action.payload,
        },
      };
    case LOAD_PREFIX:
      return {
        ...state,
        prefix: {
          ...action.payload,
        },
      };
    case LOAD_SUFFIX:
      return {
        ...state,
        suffix: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
