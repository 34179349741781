import './App.css';
import Extension from './Extension';
import Configure from './components/Configure';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import FormatState from './context/Formatting/formatState';
import KPIState from './context/KPI/kpiState';

function App() {
  return (
    <KPIState>
      <FormatState>
        <Router>
          <Switch>
            <Route exact path='/' component={Extension} />
            <Route path='/configure' component={Configure} />
          </Switch>
        </Router>
      </FormatState>
    </KPIState>
  );
}

export default App;
