import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import FormatContext from '../context/Formatting/formatContext';
import { DropdownSelect, Checkbox } from '@tableau/tableau-ui';
import ColorPicker from './ColorPicker';
import {
  AiOutlineBorderBottom,
  AiOutlineBorderLeft,
  AiOutlineBorderRight,
  AiOutlineBorderTop,
} from 'react-icons/ai';

const FormatOptions = ({ type, value }) => {
  const [check, setCheck] = useState({
    padding: false,
    margin: false,
  });
  const formatContext = useContext(FormatContext);
  const {
    kpiFormat,
    setBgColor,
    setFontColor,
    setFontSize,
    setTextAlign,
    setDecimals,
    setFontFamily,
    setInput,
    setMargin,
    setPadding,
  } = formatContext;
  const aligns = [
    { type: 'flex-start', text: 'Left' },
    { type: 'center', text: 'Center' },
    { type: 'flex-end', text: 'Right' },
  ];

  const fonts = [
    'Arial',
    'Helvetics',
    'Verdana',
    'Tahoma',
    'Times New Roman',
    'Georgia',
    'Lucida Console',
  ];

  const positions = ['top', 'right', 'bottom', 'left'];

  const changeMargin = (position, val) => {
    if (val >= 0) {
      if (check.margin) {
        positions.forEach((posi) => {
          if (val === '') {
            setMargin(type, posi, 0);
          } else {
            setMargin(type, posi, val);
          }
        });
      } else {
        if (val === '') {
          setMargin(type, position, 0);
        } else {
          setMargin(type, position, val);
        }
      }
    }
  };

  const changePadding = (position, val) => {
    if (val >= 0) {
      if (check.padding) {
        positions.forEach((posi) => {
          if (val === '') {
            setPadding(type, posi, 0);
          } else {
            setPadding(type, posi, val);
          }
        });
      } else {
        if (val === '') {
          setPadding(type, position, 0);
        } else {
          setPadding(type, position, val);
        }
      }
    }
  };

  return (
    <div>
      {(type === 'prefix' || type === 'suffix') && (
        <Wrapper>
          <Title>Input</Title>
          <Content>
            <Input
              type={'text'}
              value={value.input}
              placeholder={'IRR...'}
              onChange={(event) => setInput(type, event.target.value)}
            />
          </Content>
        </Wrapper>
      )}

      <Wrapper>
        <Title>Background Color </Title>
        <Content>
          <ColorPicker color={value.bgColor} changeColor={(hex) => setBgColor(type, hex)} />
        </Content>
      </Wrapper>

      <Wrapper>
        <Title>Font Color</Title>
        <Content>
          <ColorPicker color={value.fontColor} changeColor={(hex) => setFontColor(type, hex)} />
        </Content>
      </Wrapper>

      <Wrapper>
        <Title>Font Size (px)</Title>
        <Content>
          <Input
            type={'number'}
            value={value.fontSize}
            placeholder={value.fontSize}
            onChange={(event) => setFontSize(type, event.target.value)}
          />
        </Content>
      </Wrapper>

      <Wrapper>
        <Title>Font Family</Title>
        <Content>
          <DropContainer>
            <DropdownSelect kind='outline' onChange={(e) => setFontFamily(type, e.target.value)}>
              <option> Select Font Family </option>;
              {fonts.map((font) => {
                if (value.fontFamily === font) {
                  return (
                    <option selected key={font}>
                      {font}
                    </option>
                  );
                } else {
                  return <option key={font}> {font} </option>;
                }
              })}
            </DropdownSelect>
          </DropContainer>
        </Content>
      </Wrapper>

      <Wrapper>
        <Title>Text Align</Title>
        <Content>
          <RowFlex>
            {aligns.map((align) => {
              return align.type === value.textAlign ? (
                <Align
                  key={align.type}
                  highlighted='highlighted'
                  align={align.type}
                  onClick={() => {
                    setTextAlign(type, align.type);
                  }}
                >
                  <AlignLable highlighted>{align.text}</AlignLable>
                </Align>
              ) : (
                <Align
                  key={align.type}
                  align={align.type}
                  onClick={() => {
                    setTextAlign(type, align.type);
                  }}
                >
                  <AlignLable highlighted>{align.text}</AlignLable>
                </Align>
              );
            })}
          </RowFlex>
        </Content>
      </Wrapper>

      {type === 'kpi' && (
        <Wrapper>
          <Title>Decimals</Title>
          <Content>
            <Input
              type={'number'}
              placeholder={'68.12, 68.123, 68.1234....'}
              value={value.decimals}
              onChange={(event) => setDecimals(event.target.value)}
            />
          </Content>
        </Wrapper>
      )}

      <Wrapper>
        <Title>Margin (px)</Title>
        <BorderContainer>
          <BorderValues>
            <BorderContent>
              <AiOutlineBorderTop />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.margin.top}
                onChange={(event) => changeMargin(positions[0], event.target.value)}
              />
            </BorderContent>

            <BorderContent>
              <AiOutlineBorderRight />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.margin.right}
                onChange={(event) => changeMargin(positions[1], event.target.value)}
              />
            </BorderContent>

            <BorderContent>
              <AiOutlineBorderBottom />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.margin.bottom}
                onChange={(event) => changeMargin(positions[2], event.target.value)}
              />
            </BorderContent>

            <BorderContent>
              <AiOutlineBorderLeft />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.margin.left}
                onChange={(event) => changeMargin(positions[3], event.target.value)}
              />
            </BorderContent>
          </BorderValues>
          <Equal>
            <Checkbox
              checked={check.margin}
              onChange={(e) => setCheck({ margin: e.target.checked })}
            >
              All same
            </Checkbox>
          </Equal>
        </BorderContainer>
      </Wrapper>

      <Wrapper>
        <Title>Padding (px)</Title>
        <BorderContainer>
          <BorderValues>
            <BorderContent>
              <AiOutlineBorderTop />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.padding.top}
                onChange={(event) => changePadding(positions[0], event.target.value)}
              />
            </BorderContent>

            <BorderContent>
              <AiOutlineBorderRight />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.padding.right}
                onChange={(event) => changePadding(positions[1], event.target.value)}
              />
            </BorderContent>

            <BorderContent>
              <AiOutlineBorderBottom />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.padding.bottom}
                onChange={(event) => changePadding(positions[2], event.target.value)}
              />
            </BorderContent>

            <BorderContent>
              <AiOutlineBorderLeft />
              <InputBox
                type={'number'}
                placeholder={'?px'}
                value={value.padding.left}
                onChange={(event) => changePadding(positions[3], event.target.value)}
              />
            </BorderContent>
          </BorderValues>
          <Equal>
            <Checkbox
              checked={check.padding}
              onChange={(e) => setCheck({ padding: e.target.checked })}
            >
              All same
            </Checkbox>
          </Equal>
        </BorderContainer>
      </Wrapper>
    </div>
  );
};

const Equal = styled.div`
  margin-top: 10px;
`;

const BorderValues = styled.div`
  display: flex;
`;

const BorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BorderContent = styled.div`
  max-width: 20px;
  margin: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputBox = styled.input`
  width: 40px;
  margin-top: 5px;
  text-align: center;
`;

const RowFlex = styled.div`
  display: flex;
`;

const Align = styled.div`
  border: ${({ highlighted }) =>
    highlighted === 'highlighted' ? '1px solid black' : '1px solid #dddddd'};
  margin: 0 5px;
  width: 60px;
  font-size: 14px;
  display: flex;
  justify-content: ${({ align }) => align};
  cursor: pointer;
`;

const AlignLable = styled.h1`
  font-size: 10px;
  font-weight: 300;
  padding: 0 5px;
  color: #333333;
`;

const Input = styled.input`
  /* width: 10px; */
  padding: 5px;
`;

const DropContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.h4`
  font-size: 0.9rem;
  font-weight: 500;
  color: grey;
  margin-right: 10px;
  display: inline;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FormatOptions;
