// ---------- CONSTANTS ----------------------------
export const KPI = 'kpi';
export const PREFIX = 'prefix';
export const SUFFIX = 'suffix';

// ---------- FORMAT ----------------------------
export const SET_BGCOLOR = 'SET_BGCOLOR';
export const SET_FONTCOLOR = 'SET_FONTCOLOR';
export const SET_FONTSIZE = 'SET_FONTSIZE';
export const SET_FONTFAMILY = 'SET_FONTFAMILY';
export const SET_TEXTALIGN = 'SET_TEXTALIGN';
export const SET_DECIMAL = 'SET_DECIMAL';
export const SET_INPUT = 'SET_INPUT';
export const SET_CHECK = 'SET_CHECK';
export const SET_PADDING = 'SET_PADDING'
export const SET_MARGIN = 'SET_MARGIN'
export const LOAD_KPI = 'LOAD_KPI'; 
export const LOAD_PREFIX = 'LOAD_PREFIX'; 
export const LOAD_SUFFIX = 'LOAD_SUFFIX'; 
export const LOAD_CHECK = 'LOAD_CHECK';


// ---------- KPI ----------------------------
export const SET_CONGFIGURE = 'SET_CONGFIGURE';
export const SET_WORKSHEET = 'SET_WORKSHEET';
export const SET_KPI = 'SET_KPI'
// ---------- IRR ----------------------------
export const SET_YEAR = 'SET_YEAR';
export const SET_VALUE = 'SET_VALUE';