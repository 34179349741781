import React, { useContext } from "react";
import styled from "styled-components";
import FormatContext from "../context/Formatting/formatContext";
import { ToggleSwitch } from "@tableau/tableau-ui";
import Render from "./Render";

import FormatOptions from "./FormatOptions";

const Formatting = () => {
  const formatContext = useContext(FormatContext);
  const {
    kpiFormat,
    prefix,
    suffix,
    prefixCheck,
    suffixCheck,
    setCheck,
  } = formatContext;

  return (
    <Format>
      <Container>
        {/* -------------------------  KPI FORMAT OPTIONS  ------------------------- */}
        <SectionWrapper>
          <SectionTitle>KPI Style</SectionTitle>
          <FormatOptions type={"kpi"} value={kpiFormat} />
        </SectionWrapper>

        <Line />

        {/* -------------------------  PREFIX FORMAT OPTIONS  ------------------------- */}
        <SectionWrapper>
          <FlexWrapper>
            <SectionTitle>Prefix Style</SectionTitle>

            <Wrapper>
              <Content>
                <ToggleSwitch
                  checked={prefixCheck}
                  onChange={(e) => setCheck("prefix", e.target.checked)}
                ></ToggleSwitch>
              </Content>
            </Wrapper>
          </FlexWrapper>

          {prefixCheck ? (
            <FormatOptions type={"prefix"} value={prefix} />
          ) : (
            <p>Display text above the KPI Value</p>
          )}
        </SectionWrapper>

        <Line />

        {/* -------------------------  SUFFIX FORMAT OPTIONS  ------------------------- */}
        <SectionWrapper>
          <FlexWrapper>
            <SectionTitle>Suffix Style</SectionTitle>

            <Wrapper>
              <Content>
                {/* <Checkbox checked={prefixCheck} onChange={(e) => setCheck("prefix", e.target.checked)}>  <Title>Enable Prefix</Title></Checkbox> */}
                <ToggleSwitch
                  checked={suffixCheck}
                  onChange={(e) => setCheck("suffix", e.target.checked)}
                ></ToggleSwitch>
              </Content>
            </Wrapper>
          </FlexWrapper>

          {suffixCheck ? (
            <FormatOptions type={"suffix"} value={suffix} />
          ) : (
            <p>Display text below the KPI Value</p>
          )}
        </SectionWrapper>
      </Container>

      {/* -------------------------  Live Preview  ------------------------- */}
      <PreviewContainer>
        <Fixed>
            <Render irrValue={0.68} />
        </Fixed>
      </PreviewContainer>
    </Format>
  );
};

const Fixed = styled.div`
  position: fixed;
  width: 30%;
`;


const Format = styled.div`
  display: flex;
`;

const PreviewContainer = styled.div`
  flex: 1;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
`;

const Container = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;


const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* border: 1px solid red; */
`;

const Line = styled.div`
  border-bottom: 2px solid grey;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SectionWrapper = styled.div``;

const SectionTitle = styled.h1`
  font-size: 1.3rem;
  margin-bottom: 10px;
`;


const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Formatting;
