import { SET_CONGFIGURE, SET_WORKSHEET, SET_KPI, SET_YEAR, SET_VALUE } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
      case SET_CONGFIGURE:
          return {
              ...state,
              ...action.payload
          }
    case SET_WORKSHEET:
      return {
        ...state,
        worksheet: action.payload,
      };
    case SET_KPI:
      return {
        ...state,
        kpi: action.payload,
      };
    case SET_YEAR:
      return {
        ...state,
        irr: {
          ...state.irr,
          year: action.payload,
        },
      };
    case SET_VALUE:
      return {
        ...state,
        irr: {
          ...state.irr,
          value: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
