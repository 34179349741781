/* eslint-disable react-hooks/exhaustive-deps */
/* global tableau */
import React, { useEffect, useState, useContext } from 'react';
import { Button, Tabs } from '@tableau/tableau-ui';
import styled from 'styled-components';
import KPI from './KPI';
import Formatting from './Formatting';
import About from './About';
import FormatContext from '../context/Formatting/formatContext';
import KPIContext from '../context/KPI/kpiContext';

const Configure = () => {
  const [disable, setDisable] = useState(true);

  const kpiContext = useContext(KPIContext);
  const { kpi, worksheet, irr, loadKPI, stateValue } = kpiContext;
  const { year, value } = irr;
  const formatContext = useContext(FormatContext);
  const {
    kpiFormat,
    prefix,
    suffix,
    prefixCheck,
    suffixCheck,
    loadKPIFormat,
    loadPrefix,
    loadSuffix,
    loadCheck,
  } = formatContext;

  useEffect(() => {
    if (worksheet !== '' && kpi !== '' && year !== '' && value !== '') {
      setDisable(false);
    } else {
      setDisable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiContext]);

  useEffect(() => {
    tableau.extensions.initializeAsync({ configure }).then(function () {
      const kpiJson = tableau.extensions.settings.get('kpi');
      const formatJson = tableau.extensions.settings.get('format');
      const prefixJson = tableau.extensions.settings.get('prefix');
      const suffixJson = tableau.extensions.settings.get('suffix');
      const prefixCheckJson = tableau.extensions.settings.get('prefixCheck');
      const suffixCheckJson = tableau.extensions.settings.get('suffixCheck');
      if (prefixCheckJson !== undefined)
        loadCheck('prefix', JSON.parse(prefixCheckJson).prefixCheck);
      if (suffixCheckJson !== undefined)
        loadCheck('suffix', JSON.parse(suffixCheckJson).suffixCheck);
      if (kpiJson !== undefined) loadKPI(JSON.parse(kpiJson));
      if (formatJson !== undefined) loadKPIFormat(JSON.parse(formatJson));
      if (prefixJson !== undefined) loadPrefix(JSON.parse(prefixJson));
      else console.log('No Prefix');
      if (suffixJson !== undefined) loadSuffix(JSON.parse(suffixJson));
      else console.log('No Suffx');
    });
  }, []);

  const configure = () => {};

  const onSubmit = () => {
    console.log(prefixCheck);
    if (prefixCheck) {
      tableau.extensions.settings.set('prefixCheck', JSON.stringify({ prefixCheck: true }));
      tableau.extensions.settings.set('prefix', JSON.stringify(prefix));
    } else {
      tableau.extensions.settings.set('prefixCheck', JSON.stringify({ prefixCheck: false }));
    }

    if (suffixCheck) {
      tableau.extensions.settings.set('suffixCheck', JSON.stringify({ suffixCheck: true }));
      tableau.extensions.settings.set('suffix', JSON.stringify(suffix));
    } else {
      tableau.extensions.settings.set('suffixCheck', JSON.stringify({ suffixCheck: false }));
    }

    const formatJson = JSON.stringify(kpiFormat);
    const kpiJson = JSON.stringify(stateValue);
    console.log(formatJson);
    tableau.extensions.settings.set('kpi', kpiJson);
    tableau.extensions.settings.set('format', formatJson);

    tableau.extensions.settings
      .saveAsync()
      .then((result) => {})
      .catch((error) => {});

    tableau.extensions.ui.closeDialog(true);
  };

  const tabs = [
    {
      content: 'Select KPI',
      node: <KPI />,
    },
    { content: 'Format', node: <Formatting /> },
    { content: 'About', node: <About /> },
  ];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <Container>
      <Tabs
        alignment='justified'
        activation='automatic'
        onTabChange={(index) => {
          setSelectedTabIndex(index);
        }}
        selectedTabIndex={selectedTabIndex}
        tabs={tabs}
        style={{ padding: 0 }}
      >
        <span>{tabs[selectedTabIndex].node}</span>
      </Tabs>
      <ButtonContainer>
        <Button disabled={disable} kind='primary' onClick={onSubmit}>
          OK
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
`;

export default Configure;

// closeDialog(payload?: undefined | string): void
//
/*
1. Get worksheets
2. Display worksheets in a dropdown menu
3. Display Dropdown to select KPI
4. Select Columns
5. Calculate IRR after OK Button is Pressed
6. Display IRR as Percentage in the Extension Box
*/
